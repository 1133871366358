import COLORS from "./Colors";

export const ROLE_CONFIG_KEYS = {
  analytics: "Analytics",
  cart: "Cart",
  customers: "Customers",
  groups: "Groups",
  inventory: "Inventory",
  broadcasts: "Broadcasts",
  quotations: "Quotation",
  notifications: "Notifications",
  order: "Order",
  outstanding: "Outstanding",
  payment: "Payment",
  preferences: "Preferences",
  profile: "Profile",
  representatives: "Representatives",
  return: "Return",
  specialOffers: "Special Offers",
  suppliers: "Suppliers",
  user: "User",
  invoices: "Invoices",
  coupons: "Coupons",
  roleSwitch: "Role_Switch",
  subUserLogin: "Sub_User_Login",
  modeSwitch: "Mode_Switch",
  specialOrder: "Special_Order",
  subscriptions: "Subscriptions",
  creditNote: "Credit_Notes",
  settings: "Settings",
  tracking: "Tracking",
  logistics: "Logistics"
};

export const FETCH_MODE = {
  supplier: "supplier",
  customer: "customer"
};

export const CURRENT_MODE = {
  sale: "Sale",
  purchase: "Purchase"
};

export const DATE_FORMAT = {
  standard_dmy: "DD/MM/YYYY",
  hyphen_ymd: "YYYY-MM-DD",
  dmy_with_time: "DD/MM/YYYY hh:mm a",
  dmy_with_comma: "DD MMM, YY",
  standard_dmy_with_time: "Do MMMM YYYY, h:mm a"
};

export const ORDER_TYPE = {
  return: "Return",
  sale: "Sale"
};

export const ORDER_SUB_TYPE = {
  normal: "Normal",
  specialBonus: "Special Bonus",
  specialRate: "Special Rate",
  specialStandardBonus: "Special Standard Bonus"
};

export const PERMISSIONS = {
  read: "Read",
  create: "Create",
  select: "Select",
  delete: "Delete",
  update: "Update"
};

export const PAYMENT_TYPE = {
  offline: "OFFLINE",
  online: "ONLINE"
};

export const PAYMENT_STATUS = {
  pending: "Pending",
  processing: "Processing"
};

export const ROLE = {
  salesman: "ROLE_SALESMAN",
  distributor: "ROLE_DISTRIBUTOR",
  retailer: "ROLE_RETAILER",
  cfa: "ROLE_CFA",
  approver: "ROLE_APPROVER",
  groupAdmin: "ROLE_ADMIN",
  marketingRep: "ROLE_MARKETING_REP",
  manufacturer: "ROLE_MANUFACTURER",
  analytics: "ROLE_ANALYTICS"
};

export const REQUEST_STATUS = {
  SUBMITTED: "SUBMITTED",
  APPROVED: "APPROVED",
  DECLINED: "DECLINED",
  BLOCKED: "BLOCKED"
};

export const ORDER_STATUS = {
  cancelled: "CANCELLED",
  delivered: "DELIVERED",
  invoiced: "INVOICED",
  processing: "PROCESSING",
  received: "RECEIVED"
};

export const INVOICE_STATUS = {
  delivered: "DELIVERED",
  dispatched: "DISPATCHED"
};

export const USER_MODE = {
  READ: "read",
  EDIT: "edit"
};

export const QUOTATION_STATUS = {
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
  DEACTIVATED: "De-activated",
  PENDING: "PENDING"
};

export const QUOTATION_UPDATES = {
  ACCEPT: "Accept",
  REJECT: "Reject",
  DETAILS: "Details",
  DEACTIVATE: "De-activate"
};
export const approvalActionsMappings = {
  APPROVED: { title: "Approve", color: COLORS.action_approve },
  DECLINED: { title: "Decline", color: COLORS.action_decline },
  REVIEW: { title: "Review", color: COLORS.action_review }
};

export const NOTIFICATION_TYPE = {
  order: "ORDER",
  offer: "OFFER",
  coupon: "COUPON",
  quotation: "QUOTATION",
  newDistributor: "NEW_DISTRIBUTOR",
  specialOrder: "SPECIAL_ORDER",
  payment: "PAYMENT",
  creditNote: "CREDIT_NOTE",
  invoice: "INVOICE",
  associationRequest: "ASSOCIATION_REQUEST"
};

export const BOOLEAN_ACTION = {
  yes: "Yes",
  no: "No"
};
