import { lazy, Suspense } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { BrowserRouter, Route, Routes, useLocation, Navigate } from "react-router-dom";
import { Spin } from "antd";
import UserContextProvider from "../common/contexts/UserContext";
import AuthContextProvider from "../common/contexts/AuthContext";
import ErrorBoundary from "../common/components/ErrorBoundary";
import Login from "./Login";
import Navbar from "../common/components/Navbar";
import Playlists from "./playlists";
import Advertisements from "./Advertisements";
import { useAuthContext } from "../common/contexts/AuthContext";

const AppLayout = lazy(() => import("../common/components/AppLayout"));
const Devices = lazy(() => import("./devices/index"));

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1
    }
  }
});

function App() {
  return (
    <AuthContextProvider>
      <QueryClientProvider client={queryClient}>
        <UserContextProvider>
          <BrowserRouter>
            <ErrorBoundary>
              <AppContent />
            </ErrorBoundary>
          </BrowserRouter>
          <ReactQueryDevtools initialIsOpen={false} />
        </UserContextProvider>
      </QueryClientProvider>
    </AuthContextProvider>
  );
}

function AppContent() {
  const { pathname } = useLocation();

  // Function to check if the current route is the login page
  const isLoginPage = pathname === "/login";
  
  return (
    <>
      {/* Conditionally render Navbar based on current route */}
      {!isLoginPage && <Navbar />}
      <Suspense fallback={<Spin spinning>Loading your content...</Spin>}>
        <Routes>
          <Route path="/" element={<Devices />} />
          <Route path="/playlists" element={<Playlists />} />
          <Route path="/ads" element={<Advertisements />} />
          <Route path="/login" element={<Login />} />
        </Routes>
      </Suspense>
    </>
  );
}

export default App;
