import { useEffect, useImperativeHandle, useRef } from "react";
import styled from "styled-components";
import PowerIcon from "../icons/Power.svg";
import SearchGray from "../icons/SearchGray.svg";
import SearchGreen from "../icons/SearchGreen.svg";
import CloseIcon from "../icons/cross.svg";

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  height: 50px;
  border-radius: 4px;
  border: 1px solid rgba(0, 87, 84, 0.1);
  background: linear-gradient(180deg, #fff 0%, #f5f5f5 100%);
  box-shadow: 0px 4px 6px -3px rgba(154, 188, 187, 0.6);
`;
const SearchImage = styled.img`
  height: 24px;
  width: 24px;
  margin-left: 16px;
  margin-right: 6px;
  @media screen and (max-width: 1279px) {
    height: 20px;
    width: 20px;
    margin-left: 12px;
  }
`;
const SearchInput = styled.input`
  border: none;
  background-color: transparent;
  outline: none;
  display: flex;
  flex: 1;
  color: rgba(0, 87, 84, 0.8);
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 120% */
  ::placeholder {
    color: #afafaf;
  }
  @media screen and (max-width: 1279px) {
    font-size: 14px;
  }
`;
const ShortcutText = styled.div`
  display: flex;
  column-gap: 6px;
  color: #afafaf;
  text-align: right;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  white-space: nowrap;
  align-items: center;
  margin-right: 16px;
  @media screen and (max-width: 1279px) {
    font-size: 12px;
  }
`;
const CrossSymbol = styled.div`
  display: flex;
  column-gap: 6px;
  color: #afafaf;
  text-align: right;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  white-space: nowrap;
  align-items: center;
  margin-right: 16px;
  cursor: pointer;
  @media screen and (max-width: 1279px) {
    font-size: 12px;
  }
`;
const Img = styled.img`
  width: 24px;
  height: 24px;
  opacity: 0.5;
  @media screen and (max-width: 1279px) {
    width: 20px;
    height: 20px;
  }
`;

const SearchComponent = (props) => {
  const {
    inputRef = null,
    placeholder,
    searchText,
    setSearchText,
    setPagination = () => {},
    pagination = { pageSize: 10, currentPage: 0 }
  } = props;
  const searchRef = useRef();

  useImperativeHandle(inputRef, () => ({
    focusInput: () => {
      if (searchRef.current) {
        searchRef.current.focus();
      }
    }
  }));
  const handleClearClick = () => {
    setSearchText('');
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "/") {
        if (searchRef.current) {
          event.preventDefault();
          searchRef.current.focus();
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <SearchContainer>
      <SearchImage
        src={searchText?.length === 0 ? SearchGray : SearchGreen}
        alt="SearchGreen"
      />
      <SearchInput
        ref={searchRef}
        autoFocus
        value={searchText}
        placeholder={placeholder}
        onChange={(e) => {
          setSearchText(e.target.value);
          setPagination({
            currentPage: 0,
            pageSize: pagination.pageSize
          });
        }}
      />
      <ShortcutText>
        <Img src={PowerIcon} alt="power" />
        Press /
      </ShortcutText>
      {searchText && (
        <CrossSymbol>
          <Img  src = {CloseIcon} onClick={handleClearClick} />
        </CrossSymbol>
      )}
    </SearchContainer>
  );
};

export default SearchComponent;
