import React from "react";
import { Trans } from "react-i18next";
import { ErrorMessage } from "./StyledElements";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return (
        <ErrorMessage>
          <Trans i18nKey="something_wrong" />
        </ErrorMessage>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
