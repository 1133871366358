import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';
import { message } from 'antd';
import { api } from '../utils/APIMethods';
import APIConstants from '../constants/APIConstants';
import { useAuthContext } from '../contexts/AuthContext';

import COLORS from '../constants/Colors';
import ads from "../icons/Ads.png";
import devices from '../icons/Devices.png';
import playlists from "../icons/Playlists.png";
import logoutIcon from "../icons/LogOutGreen.svg";

const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${COLORS.pale_cyan};
  color: ${COLORS.midnight_green};
  border-bottom: 1px solid rgba(0, 87, 84, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  padding: 0.75rem 1.5rem;
  z-index: 1000;
  box-shadow: 0 4px 6px -1px rgba(0, 87, 84, 0.1);
  font-family: 'Poppins', sans-serif;
  margin-bottom: 0;
`;

const Logo = styled.img`
  height: 50px;
  margin-right: 10px;
`;

const EnneaButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: bold;
  font-size: 24px;
  color: ${COLORS.primary_green};
`;

const LinksContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 20px;
`;

const RightLinksContainer = styled.div`
  display: flex;
  align-items: center;
`;

const NavElement = styled.div`
  ${(props) =>
    props.match &&
    css`
      color: white;
      background-color: ${COLORS.wood_rush};
      border-radius: 5px;
    `}
`;

const LinkElement = styled(Link)`
  display: flex;
  align-items: center;
  color: ${(props) => (props.match ? "white" : COLORS.midnight_green)};
  text-decoration: none;
  padding: 10px 20px;
  margin: 0 5px;
  border-radius: 5px;
  &:hover {
    background-color: ${(props) => (props.match ? COLORS.wood_rush : COLORS.light_gray)};
    color: ${(props) => (props.match ? "white" : COLORS.midnight_green)};
  }
`;

const Texts = styled.div`
  padding-left: 5px;
  font-size: 16px;
  font-weight: 400;
`;

const Icon = styled.img`
  height: 24px;
  margin-right: 8px;
`;

const LogoutButton = styled.div`
  display: flex;
  align-items: center;
  color: ${COLORS.midnight_green};
  cursor: pointer;
  margin-right: 100px;
`;

const PageContent = styled.div`
  margin-top: 3vh;
  padding: 20px;
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
`;

const ModalContent = styled.div`
  background: white;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 400px;
  text-align: center;
`;

const ModalButton = styled.button`
  background: ${(props) => (props.primary ? "red" : COLORS.primary_green)};
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 5px;
`;

const Navbar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { authConfig = {}, dispatch } = useAuthContext();
  const [showLogoutModal, setShowLogoutModal] = useState(false);

  const { mutate: logoutMutation } = useMutation(
    () =>
      api(
        {
          url: `${APIConstants.logoutURL}`
        },
        authConfig
      ),
    {
      onSuccess: () => {
        dispatch({ type: "onLogout" });
        message.success("Logout successful.");
        setShowLogoutModal(false);
        navigate("/login");
        window.location.reload();
        localStorage.clear();
      },
      onError: (error) => {
        message.error(error.message);
      }
    }
  );

  const handleLogout = () => {
    logoutMutation()
  };

  return (
    <>
      <NavbarContainer>
        <EnneaButton>
          CMS
        </EnneaButton>
        <LinksContainer>
          <NavElement match={location.pathname === '/'}>
            <LinkElement to="/" match={location.pathname === '/'}>
              <Icon src={devices} alt="Devices" />
              <Texts>Devices</Texts>
            </LinkElement>
          </NavElement>
          <NavElement match={location.pathname === '/playlists'}>
            <LinkElement to="/playlists" match={location.pathname === '/playlists'}>
              <Icon src={playlists} alt="Playlists" />
              <Texts>Playlists</Texts>
            </LinkElement>
          </NavElement>
          <NavElement match={location.pathname === '/ads'}>
            <LinkElement to="/ads" match={location.pathname === '/ads'}>
              <Icon src={ads} alt="Advertisements" />
              <Texts>Advertisements</Texts>
            </LinkElement>
          </NavElement>
        </LinksContainer>
        <RightLinksContainer>
          <LogoutButton onClick={() => setShowLogoutModal(true)}>
            <Icon src={logoutIcon} alt="Logout" />
            <Texts>Logout</Texts>
          </LogoutButton>
        </RightLinksContainer>
      </NavbarContainer>
      
      {showLogoutModal && (
        <ModalOverlay>
          <ModalContent>
            <p>Are you sure you want to logout?</p>
            <div>
              <ModalButton primary onClick={handleLogout}>Yes</ModalButton>
              <ModalButton onClick={() => setShowLogoutModal(false)}>Cancel</ModalButton>
            </div>
          </ModalContent>
        </ModalOverlay>
      )}
    </>
  );
};

export default Navbar;
