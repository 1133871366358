/* eslint-disable jsx-a11y/label-has-associated-control */
import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Form,
  Input,
  message,
  Modal,
  Popconfirm,
  Space,
  Spin,
  Upload
} from "antd";
import { useEffect, useRef, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { Navigate } from "react-router-dom";

import styled from "styled-components";
import SearchComponent from "../../common/components/SearchComponent";
import { StyledAntdTable } from "../../common/components/StyledElements";
import APIConstants from "../../common/constants/APIConstants";
import { useAuthContext } from "../../common/contexts/AuthContext";
import { api } from "../../common/utils/APIMethods";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px;
  background-color: #f8fbfb;
  height: 100vh;
  overflow: hidden;
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  height: 50px;
  width: 200px;
  border-radius: 8px;
`;

const StyledTable = styled(StyledAntdTable)`
  .ant-table-thead > tr > th {
    font-size: 18px;
    font-weight: bold;
  }
  .ant-table-tbody > tr > td {
    cursor: pointer;
    font-size: 16px;
    font-weight: 400;
    padding: 16px 8px;
    white-space: pre-wrap;
  }
  .ant-pagination-item-active a {
    color: #1890ff !important;
  }
  .ant-pagination-item:hover a {
    color: #1890ff !important;
  }
  .ant-pagination-item-active {
    border-color: #1890ff !important;
  }
  .ant-pagination-item:hover {
    border-color: #1890ff !important;
  }
  .ant-table-header {
    position: sticky;
    top: 0;
    z-index: 1;
    background: #fff;
  }
  border-radius: 10px;
`;

const TableContainer = styled.div`
  width: 100%;
  max-width: 1200px;
  background: #fff;
  padding: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
`;

const StyledSearch = styled(SearchComponent)`
  width: 100%;
  height: 100%;
  margin-bottom: 20px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  &::placeholder {
    color: #bfbfbf;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin-bottom: 20px;
`;
const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 20px; /* Add spacing between elements */
  margin-bottom: 20px;
  margin-top: 0;
`;

const SpinContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 1000;
`;

const StyledModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 8px;
  }
  .ant-modal-header {
    background-color: #f0f2f5;
    border-bottom: 1px solid #d9d9d9;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  .ant-modal-title {
    font-size: 20px;
    font-weight: bold;
  }
  .ant-modal-body {
    padding: 20px;
  }
`;

const StyledDiv = styled.div`
  margin: 5px;
`;

const StyledForm = styled(Form)`
  .ant-form-item {
    margin-bottom: 16px;
  }
  .ant-form-item-label > label {
    font-weight: 600;
    display: block;
  }
  .ant-input,
  .ant-select-selector {
    border-radius: 4px;
  }
`;

const Advertisements = () => {
  const { authConfig = {} } = useAuthContext();
  const [advertisements, setAdvertisements] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [newFileName, setNewFileName] = useState("");
  const [fileList, setFileList] = useState([]);
  const inputRef = useRef(null);
  const [searchQuery, setSearchQuery] = useState("");

  const {
    data: allAdvertisements = [],
    isLoading: isLoadingAds,
    refetch
  } = useQuery(["allAds"], () =>
    api(
      {
        url: `${APIConstants.getAds}?query=${searchQuery}`,
        method: "GET"
      },
      authConfig
    )
  );

  useEffect(() => {
    refetch();
  }, [refetch, searchQuery]);

  useEffect(() => {
    if (!isLoadingAds) {
      setAdvertisements(allAdvertisements);
    }
  }, [allAdvertisements, isLoadingAds]);

  // eslint-disable-next-line no-shadow
  const handleUploadChange = ({ fileList }) => {
    setFileList(fileList);
  };

  const { mutate: uploadMutation, isLoading: uploadLoading } = useMutation(
    (formData) =>
      api(
        {
          url: `${APIConstants.uploadAd}`,
          method: "POST",
          body: formData,
          isFormData: true
        },
        authConfig
      ),
    {
      onSuccess: () => {
        refetch();
        message.success("Advertisement uploaded successfully");
        setIsModalVisible(false);
        setNewFileName("");
        setFileList([]);
      },
      onError: (error) => {
        message.error(error.message);
      }
    }
  );

  const handleUploadConfirm = () => {
    if (fileList.length === 0) {
      message.error("Please select a file to upload.");
      return;
    }
    const formData = new FormData();
    formData.append("filename", newFileName);
    formData.append("attachment", fileList[0].originFileObj); // Only take the first file

    uploadMutation(formData);
  };

  const { mutate: advertisementDeleteMutation } = useMutation(
    (adId) =>
      api(
        {
          url: `${APIConstants.deleteAd}?advertisementId=${adId}`,
          method: "DELETE"
        },
        authConfig
      ),
    {
      onSuccess: () => {
        refetch();
        message.success("Advertisement deleted successfully");
      },
      onError: (error) => {
        message.error(error.message);
      }
    }
  );

  const handleDelete = (id) => {
    advertisementDeleteMutation(id);
  };

  const columns = [
    {
      title: "Advertisement ID",
      dataIndex: "id",
      key: "id",
      align: "center",
      width: 160
    },
    {
      title: "Advertisement Name",
      dataIndex: "fileName",
      key: "fileName",
      align: "center"
    },
    {
      title: "File Type",
      dataIndex: "fileType",
      key: "fileType",
      align: "center"
    },
    {
      title: "Actions",
      key: "actions",
      align: "center",
      render: (text, record) => (
        <Space size="middle">
          <Popconfirm
            title="Are you sure you want to delete this advertisement?"
            onConfirm={() => handleDelete(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="danger">Delete</Button>
          </Popconfirm>
        </Space>
      )
    }
  ];

  if (!authConfig.accessToken) {
    return <Navigate to="/login" />;
  }

  return (
    <Container>
      <ActionsContainer>
        <StyledSearch
          inputRef={inputRef}
          searchText={searchQuery}
          setSearchText={setSearchQuery}
          placeholder="Search Advertisments by name"
        />
        <StyledButton
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => setIsModalVisible(true)}
        >
          Upload Advertisement
        </StyledButton>
      </ActionsContainer>
      <StyledTable
        columns={columns}
        dataSource={advertisements}
        rowKey="id"
        pagination={{ showSizeChanger: true, showQuickJumper: true }}
        bordered
        loading={isLoadingAds}
        scroll={{ y: 455 }}
      />

      <StyledModal
        title="Upload Advertisement"
        visible={isModalVisible}
        onOk={handleUploadConfirm}
        onCancel={() => {
          setIsModalVisible(false);
          setFileList([]);
          setNewFileName("");
        }}
      >
        {uploadLoading && (
          <SpinContainer>
            <Spin size="large" />
          </SpinContainer>
        )}
        <StyledForm layout="vertical">
          <Form.Item>
            <label>Advertisment Name</label>
            <Input
              placeholder="Advertisement Name"
              value={newFileName}
              onChange={(e) => setNewFileName(e.target.value)}
            />
          </Form.Item>
          <Form.Item>
            <StyledDiv>
              <label>Upload File</label>
            </StyledDiv>
            <StyledDiv>
              <Upload
                fileList={fileList}
                beforeUpload={() => false}
                onChange={handleUploadChange}
                multiple={false}
                maxCount={1}
              >
                <Button icon={<UploadOutlined />}>Select File</Button>
              </Upload>
            </StyledDiv>
          </Form.Item>
        </StyledForm>
      </StyledModal>
    </Container>
  );
};

export default Advertisements;
