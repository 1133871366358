import { Button, Spin, message } from "antd";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import styled, { css } from "styled-components";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import APIConstants from "../../common/constants/APIConstants";
import COLORS from "../../common/constants/Colors";
import { phoneNumberRegex } from "../../common/constants/regex";
import { useAuthContext } from "../../common/contexts/AuthContext";
import LoginIcon from "../../common/icons/LoginPageLoginIcon.svg";
import LoginUserNameInput from "../../common/icons/LoginUserNameInput.svg";
import LoginUserPasswordInput from "../../common/icons/LoginUserPasswordInput.svg";
import LoginUserVIewPassword from "../../common/icons/LoginViewPasswordIcon.svg";
import NewEnneaLogo from "../../common/icons/NewEnneaLogo";
import { validateNumberInput } from "../../common/utils";
import { api } from "../../common/utils/APIMethods";
import { MESSAGE_TYPE, showMessage } from "../../common/utils/message";
import { ErrorElement } from "../../common/components/StyledElements";

const MainContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background: ${COLORS.login_body_gradient};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoginBodyContainer = styled.div`
  background: ${COLORS.white};
  padding: 2rem;
  border-radius: 1rem;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 500px;
  height: 600px;
  width: 100%;
`;

const WelcomeToContainer = styled.div`
  color: ${COLORS.dark_teal};
  font-family: "Poppins";
  font-size: 2rem;
  font-weight: 300;
  margin-bottom: 1rem;
`;

const ValuemediLogoContainer = styled.div`
  margin-bottom: 2rem;
`;

const LoginDetailsInputContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
  background: ${COLORS.white};
  box-shadow: 0px 3px 18px -6px rgba(1, 191, 185, 0.4);
`;

const InputElement = styled.input`
  border: none;
  outline: none;
  width: 100%;
  color: ${COLORS.dark_shaded_grey};
  font-family: "Lato";
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  ::placeholder {
    color: ${(props) => (props?.error ? COLORS.salmon_pink : COLORS.dusty_grey)};
  }
`;

const LoginDetailsIconsContainer = styled.img`
  height: 1.5rem;
  width: 1.5rem;
  margin-right: 0.5rem;
  ${(props) =>
    props.password &&
    css`
      margin-left: auto;
      cursor: pointer;
    `}
`;

const UserLoginButton = styled(Button)`
  && {
    width: 100%;
    height: 3rem;
    border-radius: 0.5rem;
    background: ${COLORS.login_button_gradient};
    box-shadow: 0px 6px 9px -4.5px rgba(0, 191, 185, 0.6);
    color: ${COLORS.white};
    font-family: "Poppins";
    font-size: 1rem;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
    gap: 30px;
  }
`;

const LoginOptionButtonContainer = styled.div`
  width: 100%;
  text-align: right;
  margin-bottom: 1rem;
`;

const LoginOptionButton = styled.span`
  color: ${COLORS.dark_teal};
  font-family: "Poppins";
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
`;

const Login = () => {
  const navigate = useNavigate();
  const locationProps = useLocation();
  const { dispatch, authConfig } = useAuthContext();
  const { loginURL, loginWithTokenURL, otpGenerate } = APIConstants;
  const [searchParams] = useSearchParams();
  const apiToken = searchParams.get("apiToken");
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const [showMobileNumberLogin, setShowMobileNumberLogin] = useState(false);

  const { isLoading: tokenLoginIsLoading } = useQuery(
    "loginWithToken",
    () =>
      api({
        url: loginWithTokenURL,
        method: "POST",
        body: {
          token: apiToken
        },
        checkAuth: false
      }),
    {
      enabled: !!apiToken,
      onSuccess: (response) => {
        dispatch({ type: "onLogin", payload: response });
        navigate("/", { replace: true });
      },
      onError: (response) => {
        showMessage(
          response?.message ?? "check_username_password",
          MESSAGE_TYPE.errorTranslate
        );
      }
    }
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues
  } = useForm({
    mode: "onChange",
    defaultValues: {
      username: locationProps?.state?.username || "",
      password: ""
    }
  });

  useEffect(() => {
    if (authConfig.sessionId && !apiToken) {
      navigate("/", { replace: true });
    }
  }, [apiToken, authConfig.sessionId, navigate]);

  const { isLoading, mutate } = useMutation(
    (formData) =>
      api({
        url: loginURL,
        method: "POST",
        body: formData,
        checkAuth: false
      }),
    {
      onSuccess: (response) => {
        dispatch({ type: "onLogin", payload: response });
        navigate("/", { replace: true });
      },
      onError: (response) => {
        showMessage(
          response?.message ?? "check_username_password",
          MESSAGE_TYPE.errorTranslate
        );
      }
    }
  );

  const onSubmit = (formData) => {
    mutate(formData);
  };

  const { isLoading: sendingOTP, mutate: otpGenerationMutation } = useMutation(
    (formData) =>
      api({
        url: `${otpGenerate}/${parseInt(formData.phoneNumber, 10)}`
      }),
    {
      onSuccess: (response) => {
        showMessage("otp_send_successful", MESSAGE_TYPE.successTranslate);
        navigate("/reset", {
          state: {
            phoneNumber: getValues("phoneNumber"),
            sessionId: response.sessionId,
            mode: 1,
            isPhoneNumberLogin: true
          }
        });
      },
      onError: (err) => {
        message.error(err.message);
      }
    }
  );

  const onPhoneNumberLogin = (formData) => {
    otpGenerationMutation(formData);
  };

  const onChangeShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleEnterKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSubmit(showMobileNumberLogin ? onPhoneNumberLogin : onSubmit)();
    }
  };

  if (tokenLoginIsLoading) {
    return <Spin />;
  }

  return (
    <MainContainer>
      <LoginBodyContainer>
        <WelcomeToContainer>
          <Trans i18nKey="welcome_to" />
        </WelcomeToContainer>
        <ValuemediLogoContainer>
          <NewEnneaLogo />
        </ValuemediLogoContainer>
        <LoginDetailsInputContainer>
          <LoginDetailsIconsContainer
            src={LoginUserNameInput}
            alt="USER_ICON"
          />
          <InputElement
            {...register("username", {
              required: t("enter_your_username")
            })}
            placeholder={t("enter your username")}
            error={errors?.username}
            onKeyPress={handleEnterKeyPress}
            onBlur={(e) => setValue("username", e.target.value.trim())}
          />
        </LoginDetailsInputContainer>
        <LoginDetailsInputContainer>
          <LoginDetailsIconsContainer
            src={LoginUserPasswordInput}
            alt="USER_PASSWORD_ICON"
          />
          <InputElement
            {...register("password", {
              required: t("enter_your_password")
            })}
            type={showPassword ? "text" : "password"}
            error={errors?.password}
            placeholder={t("enter your password")}
            onKeyPress={handleEnterKeyPress}
          />
          <LoginDetailsIconsContainer
            password
            src={LoginUserVIewPassword}
            alt="USER_PASSWORD_ICON"
            onClick={onChangeShowPassword}
          />
        </LoginDetailsInputContainer>
        <LoginOptionButtonContainer>
          <LoginOptionButton
            onClick={() => {
              setShowMobileNumberLogin(!showMobileNumberLogin);
            }}
          >
            <Trans
              i18nKey={
                showMobileNumberLogin
                  ? "login with username"
                  : "login_with_phone_number"
              }
            />
          </LoginOptionButton>
        </LoginOptionButtonContainer>
        <UserLoginButton
          loading={isLoading || sendingOTP}
          onClick={handleSubmit(
            showMobileNumberLogin ? onPhoneNumberLogin : onSubmit
          )}
        >
            Login
          <LoginDetailsIconsContainer src={LoginIcon} alt="LOGIN" />
        </UserLoginButton>
      </LoginBodyContainer>
    </MainContainer>
  );
};

export default Login;
