/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect, useRef } from "react";
import { useMutation, useQuery } from "react-query";
import { Navigate } from "react-router-dom";
import {
  Table,
  Button,
  Select,
  Space,
  Popconfirm,
  message,
  Modal,
  Input,
  Form,
  Spin,
  Row,
  Col
} from "antd";
import styled from "styled-components";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import APIConstants from "../../common/constants/APIConstants";
import { api } from "../../common/utils/APIMethods";
import { useAuthContext } from "../../common/contexts/AuthContext";
import SearchComponent from "../../common/components/SearchComponent";
import { StyledAntdTable } from "../../common/components/StyledElements";

const { Option } = Select;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px;
  background-color: #f8fbfb;
  height: 100vh;
  overflow: hidden;
`;

const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 20px; /* Add spacing between elements */
  margin-bottom: 20px;
  margin-top: 0;
`;

const SpinContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 1000;
`;

const StyledSearch = styled(SearchComponent)`
  width: 100%;
  margin: 20px 0;
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  height: 50px;
  width: 200px;
  border-radius: 8px;
`;

const StyledTable = styled(StyledAntdTable)`
  .ant-table-thead > tr > th {
    font-size: 18px;
    font-weight: bold;
  }
  .ant-table-tbody > tr > td {
    cursor: pointer;
    font-size: 16px;
    font-weight: 400;
    padding: 16px 8px;
    white-space: pre-wrap;
  }
  .ant-pagination-item-active a {
    color: #1890ff !important;
  }
  .ant-pagination-item:hover a {
    color: #1890ff !important;
  }
  .ant-pagination-item-active {
    border-color: #1890ff !important;
  }
  .ant-pagination-item:hover {
    border-color: #1890ff !important;
  }
  .ant-table-header {
    position: sticky;
    top: 0;
    z-index: 1;
    background: #fff;
  }
  border-radius: 10px;
`;

const Playlists = () => {
  const { authConfig = {} } = useAuthContext();
  const [playlists, setPlaylists] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [editingPlaylist, setEditingPlaylist] = useState(null);
  const [newPlaylistName, setNewPlaylistName] = useState("");
  const [addPlaylistName, setAddPlaylistName] = useState("");
  const [selectedAds, setSelectedAds] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState();
  const [selectedLeftImage, setSelectedLeftImage] = useState();
  const [selectedBottomImage, setSelectedBottomImage] = useState();
  const [searchQuery, setSearchQuery] = useState("");
  const inputRef = useRef(null);
  const [frameList, setFrameList] = useState([]);

  const {
    data: allPlaylists = [],
    isLoading: isLoadingPlaylists,
    refetch
  } = useQuery(["allPlaylists"], () =>
    api(
      {
        url: `${APIConstants.getPlaylists}?query=${searchQuery}`,
        method: "GET"
      },
      authConfig
    )
  );

  useEffect(() => {
    refetch();
  }, [refetch, searchQuery]);

  const {
    data: allAdvertisements = [],
    isLoading: isLoadingAds,
    refetch: refetchAds
  } = useQuery(["allAds"], () =>
    api(
      {
        url: `${APIConstants.getAds}`,
        method: "GET"
      },
      authConfig
    )
  );

  useEffect(() => {
    if (!isLoadingPlaylists) {
      const playlistsData = allPlaylists.map((playlist) => ({
        id: playlist.id,
        name: playlist.playlistName,
        ads: playlist.ads,
        frames: playlist.frames
      }));
      setPlaylists(playlistsData);
    }
  }, [allPlaylists, isLoadingPlaylists]);

  const handleEdit = (playlist) => {
    setEditingPlaylist(playlist);
    setNewPlaylistName(playlist.name);
    setFrameList(playlist.frames);
    setSelectedAds(playlist.ads.map((ad) => ad.id));
    setIsModalVisible(true);
  };

  const { mutate: playlistMutation, isLoading: updatePlaylistLoading } =
    useMutation(
      (body) =>
        api(
          {
            url: `${APIConstants.updatePlaylist}`,
            method: "PUT",
            body
          },
          authConfig
        ),
      {
        onSuccess: () => {
          refetch();
          message.success("Playlist updated successfully");
          setIsModalVisible(false);
        },
        onError: (error) => {
          message.error(error.message);
        }
      }
    );

  const handleEditConfirm = () => {
    if (!newPlaylistName) {
      message.error("Please set the Playlist Name");
      return;
    }
    playlistMutation({
      id: editingPlaylist.id,
      playlistName: newPlaylistName,
      idsList: selectedAds,
      frames: frameList
    });
  };

  const { mutate: playlistDeleteMutation } = useMutation(
    (playlistId) =>
      api(
        {
          url: `${APIConstants.deletePlaylist}?playlistId=${playlistId}`,
          method: "DELETE"
        },
        authConfig
      ),
    {
      onSuccess: () => {
        refetch();
        message.success("Playlist deleted successfully");
      },
      onError: (error) => {
        message.error(error.message);
      }
    }
  );

  const { mutate: createPlaylistMutation, isLoading: createPlaylistLoading } =
    useMutation(
      (body) =>
        api(
          {
            url: `${APIConstants.createPlaylist}`,
            method: "POST",
            body
          },
          authConfig
        ),
      {
        onSuccess: () => {
          refetch();
          message.success("Playlist created successfully");
          setIsAddModalVisible(false);
        },
        onError: (error) => {
          message.error(error.message);
        }
      }
    );

  const handleDelete = (id) => {
    playlistDeleteMutation(id);
  };

  const handleAddPlaylist = () => {
    setAddPlaylistName("");
    setSelectedAds([]);
    setFrameList([]);
    setIsAddModalVisible(true);
  };

  const OnClickEditAddFrame = () => {
    if (!selectedVideo) {
      message.error("Please select a video before adding a frame.");
      return;
    }
    setFrameList([
      ...frameList,
      {
        video: allAdvertisements.find((ad) => ad.id === selectedVideo),
        leftImage: allAdvertisements.find((ad) => ad.id === selectedLeftImage),
        bottomImage: allAdvertisements.find(
          (ad) => ad.id === selectedBottomImage
        )
      }
    ]);
    setSelectedVideo("");
    setSelectedBottomImage("");
    setSelectedLeftImage("");
  };

  const onClickAddFrame = () => {
    if (!selectedVideo) {
      message.error("Please select a video before adding a frame.");
      return;
    }
    setFrameList([
      ...frameList,
      {
        video: selectedVideo,
        leftImage: selectedLeftImage,
        bottomImage: selectedBottomImage
      }
    ]);
    setSelectedVideo("");
    setSelectedBottomImage("");
    setSelectedLeftImage("");
  };

  const handleDeleteFrame = (index) => {
    setFrameList((prevFrameList) =>
      prevFrameList.filter((_, i) => i !== index)
    );
  };

  const handleAddConfirm = () => {
    if (!addPlaylistName) {
      message.error("Please enter the name of Playlist.");
      return;
    }
    createPlaylistMutation({
      playlistName: addPlaylistName,
      idsList: selectedAds,
      framesList: frameList
    });
  };

  const handleAddModalCancel = () => {
    setIsAddModalVisible(false);
    setAddPlaylistName("");
    setSelectedAds([]);
  };

  const filteredPlaylists = playlists.filter((playlist) =>
    playlist?.name?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const columns = [
    {
      title: "Playlist ID",
      dataIndex: "id",
      key: "id",
      align: "center",
      width: 100
    },
    {
      title: "Playlist Name",
      dataIndex: "name",
      key: "name",
      align: "center"
    },
    {
      title: "Actions",
      key: "actions",
      align: "center",
      render: (text, record) => (
        <Space size="middle">
          <Button type="primary" onClick={() => handleEdit(record)}>
            Edit
          </Button>
          <Popconfirm
            title="Are you sure you want to delete this playlist?"
            onConfirm={() => handleDelete(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="danger">Delete</Button>
          </Popconfirm>
        </Space>
      )
    }
  ];
  if (!authConfig.accessToken) {
    return <Navigate to="/login" />;
  }

  const handleSelectChange = (index, key, value) => {
    const updatedFrameList = [...frameList];
    updatedFrameList[index][key] = value;
    setFrameList(updatedFrameList);
  };

  const handleEditSelectChange = (index, key, value) => {
    const updatedFrameList = [...frameList];
    updatedFrameList[index][key] = allAdvertisements.find(
      (ad) => ad.id === value
    );
    setFrameList(updatedFrameList);
  };

  const editModalColumns = [
    {
      title: "Video",
      dataIndex: "video",
      key: "video",
      render: (video, record, index) => (
        <Select
          value={video?.id}
          onChange={(value) => handleEditSelectChange(index, "video", value)}
          style={{ width: "100%" }}
          allowClear
        >
          {allAdvertisements.map((ad) => (
            <Option key={ad.id} value={ad.id}>
              {ad.fileName}
            </Option>
          ))}
        </Select>
      )
    },
    {
      title: "Left Image",
      dataIndex: "leftImage",
      key: "leftImage",
      render: (leftImage, record, index) => (
        <Select
          value={leftImage?.id}
          onChange={(value) =>
            handleEditSelectChange(index, "leftImage", value)
          }
          style={{ width: "100%" }}
          allowClear
        >
          {allAdvertisements
            .filter((ad) => ad.fileType === "image/jpeg")
            .map((ad) => (
              <Option key={ad.id} value={ad.id}>
                {ad.fileName}
              </Option>
            ))}
        </Select>
      )
    },
    {
      title: "Bottom Image",
      dataIndex: "bottomImage",
      key: "bottomImage",
      render: (bottomImage, record, index) => (
        <Select
          value={bottomImage?.id}
          onChange={(value) =>
            handleEditSelectChange(index, "bottomImage", value)
          }
          style={{ width: "100%" }}
          allowClear
        >
          {allAdvertisements
            .filter((ad) => ad.fileType === "image/jpeg")
            .map((ad) => (
              <Option key={ad.id} value={ad.id}>
                {ad.fileName}
              </Option>
            ))}
        </Select>
      )
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record, index) => (
        <Button type="danger" onClick={() => handleDeleteFrame(index)}>
          Delete
        </Button>
      )
    }
  ];
  const modalColumns = [
    {
      title: "Video",
      dataIndex: "video",
      key: "video",
      render: (text, record, index) => (
        <Select
          value={record.video}
          onChange={(value) => handleSelectChange(index, "video", value)}
          style={{ width: "100%" }}
          allowClear
        >
          {allAdvertisements.map((ad) => (
            <Option key={ad.id} value={ad.id}>
              {ad.fileName}
            </Option>
          ))}
        </Select>
      )
    },
    {
      title: "Left Image",
      dataIndex: "leftImage",
      key: "leftImage",
      render: (text, record, index) => (
        <Select
          value={record.leftImage}
          onChange={(value) => handleSelectChange(index, "leftImage", value)}
          style={{ width: "100%" }}
          allowClear
        >
          {allAdvertisements
            .filter((ad) => ad.fileType === "image/jpeg")
            .map((ad) => (
              <Option key={ad.id} value={ad.id}>
                {ad.fileName}
              </Option>
            ))}
        </Select>
      )
    },
    {
      title: "Bottom Image",
      dataIndex: "bottomImage",
      key: "bottomImage",
      render: (text, record, index) => (
        <Select
          value={record.bottomImage}
          onChange={(value) => handleSelectChange(index, "bottomImage", value)}
          style={{ width: "100%" }}
          allowClear
        >
          {allAdvertisements
            .filter((ad) => ad.fileType === "image/jpeg")
            .map((ad) => (
              <Option key={ad.id} value={ad.id}>
                {ad.fileName}
              </Option>
            ))}
        </Select>
      )
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record, index) => (
        <Button type="danger" onClick={() => handleDeleteFrame(index)}>
          Delete
        </Button>
      )
    }
  ];

  return (
    <Container>
      <ActionsContainer>
        <StyledSearch
          inputRef={inputRef}
          placeholder="Search playlists by name"
          searchText={searchQuery}
          setSearchText={setSearchQuery}
        />
        <StyledButton
          type="primary"
          icon={<PlusOutlined />}
          onClick={handleAddPlaylist}
        >
          Create Playlist
        </StyledButton>
      </ActionsContainer>

      <StyledTable
        columns={columns}
        dataSource={filteredPlaylists}
        rowKey="id"
        pagination={{ showSizeChanger: true, showQuickJumper: true }}
        bordered
        loading={isLoadingPlaylists}
        style={{ minHeight: "400px" }}
        scroll={{ y: 455 }}
      />

      <Modal
        title="Edit Playlist"
        visible={isModalVisible}
        onOk={handleEditConfirm}
        onCancel={() => setIsModalVisible(false)}
        width={1000}
      >
        {updatePlaylistLoading && (
          <SpinContainer>
            <Spin size="large" />
          </SpinContainer>
        )}
        <Form layout="vertical">
          <Form.Item
            rules={[
              { required: true, message: "Please input the playlist name!" }
            ]}
          >
            <label>Playlist Name</label>
            <Input
              placeholder="Playlist Name"
              value={newPlaylistName}
              onChange={(e) => setNewPlaylistName(e.target.value)}
            />
          </Form.Item>
          <Row gutter={16}>
            <Col span={6}>
              <Form.Item>
                <label>Select video</label>
                <Select
                  placeholder="Select Advertisements"
                  value={selectedVideo}
                  onChange={(value) => {
                    setSelectedAds([...selectedAds, value]);
                    setSelectedVideo(value);
                  }}
                  style={{ width: "100%" }}
                >
                  {allAdvertisements.map((ad) => (
                    <Option key={ad.id} value={ad.id}>
                      {ad.fileName}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item>
                <label>Select Left Image</label>
                <Select
                  placeholder="Select Advertisements"
                  value={selectedLeftImage}
                  onChange={(value) => {
                    setSelectedAds([...selectedAds, value]);
                    setSelectedLeftImage(value);
                  }}
                  style={{ width: "100%" }}
                >
                  {allAdvertisements
                    .filter((ad) => ad.fileType === "image/jpeg")
                    .map((ad) => (
                      <Option key={ad.id} value={ad.id}>
                        {ad.fileName}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item>
                <label>Select Bottom Image</label>
                <Select
                  placeholder="Select Advertisements"
                  value={selectedBottomImage}
                  onChange={(value) => {
                    setSelectedAds([...selectedAds, value]);
                    setSelectedBottomImage(value);
                  }}
                  style={{ width: "100%" }}
                >
                  {allAdvertisements
                    .filter((ad) => ad.fileType === "image/jpeg")
                    .map((ad) => (
                      <Option key={ad.id} value={ad.id}>
                        {ad.fileName}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>

            <StyledButton type="primary" onClick={OnClickEditAddFrame}>
              Add Frame
            </StyledButton>
          </Row>
        </Form>

        <Table
          columns={editModalColumns}
          dataSource={frameList.map((fr, index) => ({
            ...fr,
            key: index
          }))}
          pagination={false}
          scroll={{ y: 240 }}
        />
      </Modal>

      <Modal
        title="Create Playlist"
        open={isAddModalVisible}
        onOk={handleAddConfirm}
        onCancel={handleAddModalCancel}
        width={1000}
      >
        {createPlaylistLoading && (
          <SpinContainer>
            <Spin size="large" />
          </SpinContainer>
        )}
        <Form layout="vertical">
          <Form.Item
            rules={[
              { required: true, message: "Please input the playlist name!" }
            ]}
          >
            <label>Playlist Name</label>
            <Input
              placeholder="Playlist Name"
              value={addPlaylistName}
              onChange={(e) => setAddPlaylistName(e.target.value)}
            />
          </Form.Item>
          <Row gutter={16}>
            <Col span={6}>
              <Form.Item>
                <label>Select video</label>
                <Select
                  placeholder="Select Advertisements"
                  value={selectedVideo}
                  onChange={(value) => {
                    setSelectedAds([...selectedAds, value]);
                    setSelectedVideo(value);
                  }}
                  style={{ width: "100%" }}
                >
                  {allAdvertisements.map((ad) => (
                    <Option key={ad.id} value={ad.id}>
                      {ad.fileName}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item>
                <label>Select Left Image</label>
                <Select
                  placeholder="Select Advertisements"
                  value={selectedLeftImage}
                  onChange={(value) => {
                    setSelectedAds([...selectedAds, value]);
                    setSelectedLeftImage(value);
                  }}
                  style={{ width: "100%" }}
                >
                  {allAdvertisements
                    .filter((ad) => ad.fileType === "image/jpeg")
                    .map((ad) => (
                      <Option key={ad.id} value={ad.id}>
                        {ad.fileName}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item>
                <label>Select Bottom Image</label>
                <Select
                  placeholder="Select Advertisements"
                  value={selectedBottomImage}
                  onChange={(value) => {
                    setSelectedAds([...selectedAds, value]);
                    setSelectedBottomImage(value);
                  }}
                  style={{ width: "100%" }}
                >
                  {allAdvertisements
                    .filter((ad) => ad.fileType === "image/jpeg")
                    .map((ad) => (
                      <Option key={ad.id} value={ad.id}>
                        {ad.fileName}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>

            <StyledButton type="primary" onClick={onClickAddFrame}>
              Add Frame
            </StyledButton>
          </Row>
        </Form>

        <Table
          columns={modalColumns}
          dataSource={frameList.map((fr, index) => ({
            ...fr,
            key: index
          }))}
          pagination={false}
          scroll={{ y: 240 }}
        />
      </Modal>
    </Container>
  );
};

export default Playlists;
