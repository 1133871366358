import { DATE_FORMAT } from "../constants";

export const roundCost = (cost) => Math.round(cost * 100) / 100;

export const checkPermission = (parsedRoleConfig, { config, permission }) =>
  parsedRoleConfig?.[config]?.indexOf(permission) > -1;

export const filterConfigElements = (roleConfig, elementsConfig = []) => {
  const userElements = elementsConfig
    .filter(
      (elementConfig) =>
        roleConfig?.[elementConfig.permission.key]?.indexOf(
          elementConfig.permission.value
        ) > -1
    )
    .map((elementConfig) => elementConfig.component);
  return userElements;
};

export const getAvailabilityText = (availability = "", supplier = {}) => {
  if (supplier.settings?.displayActualFigures) {
    return availability;
  }

  return availability > 0 ? "Yes" : "No";
};

export const getHalfSchemeQuantity = (orderedQuantity, deal, free) => {
  if (free % 2 !== 0) {
    return orderedQuantity - 0.5;
  }
  return orderedQuantity;
};

export const getHalfSchemeFree = (orderedQuantity, deal, free) => {
  const schemeFreeQuantity = Math.floor(orderedQuantity / deal) * free;
  const halfSchemeFreeQuantity = free / 2;
  return schemeFreeQuantity + halfSchemeFreeQuantity;
};

export const getHalfSchemeText = (deal, supplier) => {
  if (!supplier) {
    return "--";
  }
  if (deal > 0) {
    return supplier?.settings?.usingHalfSchemes ? "Y" : "N";
  }
  return "--";
};

export const isValidQuantity = (
  quantity,
  { availability, deal = 0, free = 0, halfScheme = false }
) => {
  if (Number.isNaN(quantity)) {
    return false;
  }
  if (deal > 0) {
    if (halfScheme) {
      const hsQuantity = getHalfSchemeQuantity(quantity, deal, free);
      const hsFree = getHalfSchemeFree(quantity, deal, free);
      return hsQuantity + hsFree <= availability;
    }
    const freeQuantity = parseInt(quantity / deal, 10) * free;
    return freeQuantity + quantity <= availability;
  }
  return quantity <= availability;
};

export const isHalfSchemeApplicableWithoutSupplier = ({
  quantity,
  deal,
  free
}) => {
  if (Number.isNaN(quantity)) {
    return false;
  }
  if (deal === 0) {
    return false;
  }
  const remaining = quantity % deal;
  if (deal % 2 !== 0) {
    return remaining >= Math.ceil(deal / 2);
  }
  if (free % 2 === 0) {
    return remaining >= deal / 2;
  }
  return remaining >= deal / 2 + 1;
};

export const isHalfSchemeApplicable = ({ quantity, deal, free, supplier }) => {
  if (Number.isNaN(quantity)) {
    return false;
  }
  if (getHalfSchemeText(deal, supplier) !== "Y") {
    return false;
  }
  if (deal === 0) {
    return false;
  }
  const remaining = quantity % deal;
  if (deal % 2 !== 0) {
    return remaining >= Math.ceil(deal / 2);
  }
  if (free % 2 === 0) {
    return remaining >= deal / 2;
  }
  return remaining >= deal / 2 + 1;
};

export const getValidQuantity = (
  quantity,
  { availability, deal, free, halfScheme = false }
) => {
  if (Number.isNaN(quantity)) {
    return 0;
  }
  if (deal > 0) {
    if (halfScheme) {
      const batches = parseInt(availability / (deal + free), 10);
      const floorOrderQuantity = batches * deal;
      return floorOrderQuantity;
    }

    const batches = parseInt(availability / (deal + free), 10);
    const floorOrderQuantity = batches * deal;
    const totalBatchQuantity = batches * (deal + free);
    const remainingOrderQuantity =
      availability - totalBatchQuantity >= deal
        ? deal - 1
        : availability - totalBatchQuantity;
    return floorOrderQuantity + remainingOrderQuantity;
  }
  return availability;
};

export const getProductQuantityFromCart = (
  productId,
  supplier,
  cartDetails = []
) => {
  if (!productId || !supplier) {
    return 0;
  }
  const productInCart = cartDetails
    .filter((cartItems) => cartItems.supplier.id === supplier.id)
    .flatMap((cartItems) => cartItems.inventory)
    .filter(
      (cartProduct) => cartProduct.inventoryAvailability.id === productId
    );

  return productInCart.length > 0 ? productInCart[0].orderedQuantity : 0;
};

export const getDiscountQuantityFromCart = (
  productId,
  supplier,
  cartDetails = []
) => {
  const productInCart = cartDetails
    .filter((cartItems) => cartItems.supplier.id === supplier.id)
    .flatMap((cartItems) => cartItems.inventory)
    .filter(
      (cartProduct) => cartProduct.inventoryAvailability.id === productId
    );

  return productInCart.length > 0 ? productInCart[0]?.discount || 0 : 0;
};

export const getColor = (available) => {
  if (available === "YES") {
    return "green";
  }
  if (available === "No") {
    return "red";
  }

  if (available > 100) {
    return "green";
  }
  if (available > 10 && available <= 100) {
    return "blue";
  }
  if (available <= 10) {
    return "red";
  }
  return "";
};

export const getSpecialFreeQuantityFromCart = (
  productId,
  supplier,
  cartDetails = []
) => {
  const productInCart = cartDetails
    .filter((cartItems) => cartItems.supplier.id === supplier.id)
    .flatMap((cartItems) => cartItems.inventory)
    .filter(
      (cartProduct) => cartProduct.inventoryAvailability.id === productId
    );

  return productInCart.length > 0
    ? productInCart[0]?.specialFreeQuantity || 0
    : 0;
};

export const getUserRole = (authConfig) => authConfig?.user?.role?.role;

export const formatDate = (date, format) => {
  switch (format) {
    case DATE_FORMAT.standard_dmy: {
      return new Date(date).toLocaleDateString("en-GB"); // GB for british
    }
    default:
      return date;
  }
};

export const validateNumberInput = (evt) =>
  [".", "e", "E", "-", "+"].indexOf(evt.key) > -1 && evt.preventDefault();

export const validateFloatNumberInput = (evt) =>
  ["e", "E", "-", "+"].indexOf(evt.key) > -1 && evt.preventDefault();

export const debounce = (func, delay, { leading } = {}) => {
  let timerId;

  return (...args) => {
    if (!timerId && leading) {
      func(...args);
    }
    clearTimeout(timerId);

    timerId = setTimeout(() => func(...args), delay);
  };
};

export const numberOfDaysToToday = (date) => {
  const minutesPerDay = 1000 * 60 * 60 * 24;
  const currentDate = new Date();
  const invoiceDate = new Date(date);
  const currentDateTime = Date.UTC(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate()
  );
  const invoiceDateTime = Date.UTC(
    invoiceDate.getFullYear(),
    invoiceDate.getMonth(),
    invoiceDate.getDate()
  );
  return Math.floor((currentDateTime - invoiceDateTime) / minutesPerDay);
};

export const getAuthorizedPlaceOrderTabs = (authConfig) =>
  authConfig.roleConfig?.transactionModes
    ?.find((transactionMode) => transactionMode.name === authConfig.currentMode)
    ?.features?.find((feature) => feature?.name === "Cart")
    ?.permissionTypes?.find(
      (permissionType) => permissionType?.name === "Create"
    )
    ?.placeOrderTypePermissions?.map(
      (placeOrderTypePermission) => placeOrderTypePermission?.type
    );

export const getFormattedValue = (value = 0) =>
  Number(parseFloat(value).toFixed(2)).toLocaleString();
