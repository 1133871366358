import { message } from "antd";
// eslint-disable-next-line import/no-extraneous-dependencies
import i18next from "i18next";

export const MESSAGE_TYPE = {
  success: "success",
  warning: "warning",
  error: "error",
  successTranslate: "successTranslate",
  warningTranslate: "warningTranslate",
  errorTranslate: "errorTranslate"
};
export const showMessage = (string, type, interpolation = {}) => {
  switch (type) {
    case MESSAGE_TYPE.success:
      message.success(string);
      break;
    case MESSAGE_TYPE.successTranslate:
      message.success(i18next.t(string, interpolation));
      break;
    case MESSAGE_TYPE.warning:
      message.warning(string);
      break;
    case MESSAGE_TYPE.warningTranslate:
      message.warning(i18next.t(string, interpolation));
      break;
    case MESSAGE_TYPE.error:
      message.error(string);
      break;
    case MESSAGE_TYPE.errorTranslate:
      message.error(i18next.t(string, interpolation));
      break;
    default:
      break;
  }
};
