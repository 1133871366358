import React from "react";
import { createRoot } from "react-dom/client";
import ReactGA from "react-ga4";
import { GTMTags } from "./common/constants/APIConstants";
import "./Antd.css";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import App from "./routes";

try {
  if (GTMTags[process.env.REACT_APP_URL_ENV]) {
    ReactGA.initialize(GTMTags[process.env.REACT_APP_URL_ENV]);
  }
} catch (err) {
  console.error("Error while initializing react google analytics");
}

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
